const dataModelObjectName = (dataModelObject) => {
  let name;
  if(dataModelObject?.parentObject) {
    name = `${dataModelObject.parentObject.name}.${dataModelObject.name}`;
  } else {
    name = dataModelObject.name;
  }

  return name;
}

module.exports = {
  dataModelObjectName
}
