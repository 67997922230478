import DDTCanvasUIControl from './DDTCanvasUIControl'
import FullTextDescriptionComponent from './FullTextDescriptionComponent'
import SimpleAttachmentComponent from './SimpleAttachmentComponent'
import HelpPoint from '../../Helpers/HelpPoint'
import DisplayModeComponent from './DisplayModeComponent'
import TabSubjectDetailsDescription from '../TabSubjectDetailsDescription'
import DDTSpecificationType from './DDTSpecificationType'
import DDTDelimiter from './DDTDelimiter'
import DDTHeader from './DDTHeader'
import DDTDisplayLayout from './DDTDisplayLayout'
import DDTFooter from './DDTFooter'
import SpecCustomFields from '../../shared/SpecCustomFields'


export default class DisplayDetailsTabContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      tabSubjectHelpPoint: props.helpPoints.point1,
      simpleAttachmentHelpPoint: props.helpPoints.point14,
      delimiterElement: props.delimiter,
      onDelimiterSave: this.onDelimiterSave.bind(this),
      onDelimiterCancel: this.onDelimiterCancel.bind(this),
      toggleDelimiterEditMode: this.toggleDelimiterEditMode.bind(this),
      onDelimiterChange: this.onDelimiterChange.bind(this),
      delimiterEditMode: false,
      displayHeaderElement: props.displayHeader,
      onDisplayHeaderSave: this.onDisplayHeaderSave.bind(this),
      onDisplayHeaderCancel: this.onDisplayHeaderCancel.bind(this),
      toggleDisplayHeaderEditMode: this.toggleDisplayHeaderEditMode.bind(this),
      onDisplayHeaderChange: this.onDisplayHeaderChange.bind(this),
      displayHeaderEditMode: false,
      displayLayoutElement: props.displayLayout,
      onDisplayLayoutSave: this.onDisplayLayoutSave.bind(this),
      onDisplayLayoutCancel: this.onDisplayLayoutCancel.bind(this),
      toggleDisplayLayoutEditMode: this.toggleDisplayLayoutEditMode.bind(this),
      onDisplayLayoutChange: this.onDisplayLayoutChange.bind(this),
      displayLayoutEditMode: false,
      displayFooterElement: props.displayFooter,
      onDisplayFooterSave: this.onDisplayFooterSave.bind(this),
      onDisplayFooterCancel: this.onDisplayFooterCancel.bind(this),
      toggleDisplayFooterEditMode: this.toggleDisplayFooterEditMode.bind(this),
      onDisplayFooterChange: this.onDisplayFooterChange.bind(this),
      displayFooterEditMode: false,
      setDisplayDataItems: this.setDisplayDataItems.bind(this),
    };
    this.onDisplayModeSwitch = this.onDisplayModeSwitch.bind(this);
  }

  onDelimiterChange(obj) {
    this.setState({ delimiter: obj.target.value });
  }

  onDelimiterSave(e) {
    e.preventDefault();

    const { reportVersion, delimiter } = this.state;

    this.setState({
      delimiterEditMode: false,
      delimiterElement: delimiter,
    });

    const data = {
      report_version: {
        display_delimiter: delimiter,
        source_erp_system_ids: this.state.reportVersionErpSystemIds,
        data_tool_ids: this.state.dataToolIds,
      },
    };

    $j.ajax({
      url: `/institution/reports/${reportVersion.report_id}` +
      `/versions/${reportVersion.id}`,
      method: 'PATCH',
      data,
    });
  }

  onDelimiterCancel(e) {
    e.preventDefault();
    this.setState({
      delimiter: this.state.delimiterElement,
      delimiterEditMode: false,
    });
  }

  toggleDelimiterEditMode(e) {
    e && e.preventDefault();
    this.setState({ delimiterEditMode: !this.state.delimiterEditMode });
  }

  onDisplayHeaderChange(obj) {
    this.setState({ displayHeader: obj.target.value });
  }

  onDisplayHeaderSave(e) {
    e.preventDefault();

    const { reportVersion, displayHeader } = this.state;

    this.setState({
      displayHeaderEditMode: false,
      displayHeaderElement: displayHeader,
    });

    const data = {
      report_version: {
        display_header: displayHeader,
        source_erp_system_ids: this.state.reportVersionErpSystemIds,
        data_tool_ids: this.state.dataToolIds,
      },
    };

    $j.ajax({
      url: `/institution/reports/${reportVersion.report_id}` +
      `/versions/${reportVersion.id}`,
      method: 'PATCH',
      data,
    });
  }

  onDisplayHeaderCancel(e) {
    e.preventDefault();
    this.setState({
      displayHeader: this.state.displayHeaderElement,
      displayHeaderEditMode: false
    });
  }

  toggleDisplayHeaderEditMode(e) {
    e && e.preventDefault();
    this.setState({ displayHeaderEditMode: !this.state.displayHeaderEditMode });
  }

  onDisplayLayoutChange(obj) {
    this.setState({ displayLayout: obj.target.value });
  }

  onDisplayLayoutSave(e) {
    e.preventDefault();

    const { reportVersion, displayLayout } = this.state;

    this.setState({
      displayLayoutEditMode: false,
      displayLayoutElement: displayLayout,
    });

    const data = {
      report_version: {
        display_layout: displayLayout,
        source_erp_system_ids: this.state.reportVersionErpSystemIds,
        data_tool_ids: this.state.dataToolIds,
      },
    };

    $j.ajax({
      url: `/institution/reports/${reportVersion.report_id}` +
      `/versions/${reportVersion.id}`,
      method: 'PATCH',
      data,
    });
  }

  onDisplayLayoutCancel(e) {
    e.preventDefault();
    this.setState({
      displayLayout: this.state.displayLayoutElement,
      displayLayoutEditMode: false
    });
  }

  toggleDisplayLayoutEditMode(e) {
    e && e.preventDefault();
    this.setState({ displayLayoutEditMode: !this.state.displayLayoutEditMode });
  }

  onDisplayFooterChange(obj) {
    this.setState({ displayFooter: obj.target.value });
  }

  onDisplayFooterSave(e) {
    e.preventDefault();

    const { reportVersion, displayFooter } = this.state;

    this.setState({
      displayFooterEditMode: false,
      displayFooterElement: displayFooter,
    });

    const data = {
      report_version: {
        display_footer: displayFooter,
        source_erp_system_ids: this.state.reportVersionErpSystemIds,
        data_tool_ids: this.state.dataToolIds
      },
    };

    $j.ajax({
      url: `/institution/reports/${reportVersion.report_id}` +
      `/versions/${reportVersion.id}`,
      method: 'PATCH',
      data,
    });
  }

  onDisplayFooterCancel(e) {
    e.preventDefault();
    this.setState({
      displayFooter: this.state.displayFooterElement,
      displayFooterEditMode: false,
    });
  }

  toggleDisplayFooterEditMode(e) {
    e && e.preventDefault();
    this.setState({ displayFooterEditMode: !this.state.displayFooterEditMode });
  }

  onDisplayModeSwitch(mode, confirm) {
    const self = this;
    const { reportVersion } = this.state;
    const data = {
      report_version: {
        display_mode: mode,
        source_erp_system_ids: this.state.reportVersionErpSystemIds,
        data_tool_ids: this.state.dataToolIds,
      },
    };
    if (confirm) {
      data.confirm = confirm;
    }

    $j.ajax({
      url: `/institution/reports/${reportVersion.report_id}` +
      `/versions/${reportVersion.id}`,
      method: 'PATCH',
      data,
      success: (response) => {
        if (response.notification_message && !confirm) {
          self.initDialog(response.notification_message, mode);
        } else {
          $j('.change-display-mode-confirmation').hasClass('ui-dialog-content')
            && $j('.change-display-mode-confirmation').dialog('destroy');
          let state = {
            reportVersion: {
              ...reportVersion,
              displayMode: mode,
            },
          };
          if (mode === 'mockup' || mode === 'attachment') {
            state = Object.assign(state, {
              displayHeader: '',
              displayFooter: '',
              displayLayout: '',
              delimiter: '',
              mockupImageDescription: null,
              mockupImagePath: null,
            });
          }
          self.setState({
            ...state,
          });
        }
      },
    });
  }

  initDialog(message, mode) {
    const self = this;
    $j('.change-display-mode-confirmation').find('.change-display-mode-confirmation-warning-message').html(message);
    $j('.change-display-mode-confirmation').dialog({
      modal: true,
      dialogClass: 'dialog-shadow',
      width: 500,
      title: 'Warning: Major changes to Specification',
      overlayClose: false,
      closeValue: '',
      close: function () {
        $j('.change-display-mode-confirmation').dialog('destroy');
        return false;
      },
    });
    $j('.confirm-change-display-mode-confirmation').on('click', () => {
      self.onDisplayModeSwitch(mode, true);
    });
  }

  setDisplayDataItems(reportDisplayItems) {
    this.setState({
      reportDisplayItems,
    });
  }

  render() {
    const { visibleFields } = this.props;
    const { reportVersion } = this.state;
    const { editable } = this.props;
    const displayMode = reportVersion.displayMode || reportVersion.display_mode;
    let component = null;
    if (visibleFields.includes('Layout Control')) {
      switch (displayMode) {
        case 'mockup':
          component = <DDTCanvasUIControl helpPoints={this.props.helpPoints} {...this.state} />;
          break;
        case 'text':
          component = <FullTextDescriptionComponent {...this.state} />;
          break;
        case 'attachment':
          component = <SimpleAttachmentComponent {...this.state} />;
          break;
        default:
          component = <DDTCanvasUIControl helpPoints={this.props.helpPoints} {...this.state} />;
      }
    }
    return (
      <div>
        <div style={{ display: 'flex' }}>
          <h3>Display</h3>
          <HelpPoint helpPoint={this.props.helpPoints.point13}/>
        </div>
        {
          editable && <DisplayModeComponent
            onModeSwitch={this.onDisplayModeSwitch}
            mode={displayMode}
            helpPoint={this.props.helpPoints.point12}
          />
        }
        { visibleFields.includes("Display Description") &&
          <TabSubjectDetailsDescription
            {...this.state}
          />
        }
        { visibleFields.includes('Specification Type') && displayMode === 'text' &&
          <DDTSpecificationType
            {...this.state}
          />
        }
        { visibleFields.includes('Delimiter') && displayMode === 'text' &&
          <DDTDelimiter
            {...this.state}
          />
        }
        { visibleFields.includes('Header') && displayMode === 'text' &&
          <DDTHeader
            {...this.state}
          />
        }
        { visibleFields.includes('Display Layout') && displayMode === 'text' &&
          <DDTDisplayLayout
            {...this.state}
          />
        }
        { visibleFields.includes('Footer') && displayMode === 'text' &&
          <DDTFooter
            {...this.state}
          />
        }
        { !!visibleFields.find((f) => { return f.includes('Additional Attribute'); }) &&
          <SpecCustomFields
            {...this.state}
          />
        }
        { visibleFields.includes('Layout Control') && component }
        <div className="change-display-mode-confirmation hide">
          <span className="change-display-mode-confirmation-warning-message"></span>
          <br/>
          <b>Do you wish to continue?</b>
          <br/>
          <a href="#" className="pretty-button clear confirm-change-display-mode-confirmation">Confirm</a>
          <a className="cancel-change-display-mode-confirmation" style={{ cursor: 'pointer' }}
            onClick={()=>{ $j('.change-display-mode-confirmation').dialog('destroy'); return false; }}
          >
            Cancel
          </a>
        </div>
      </div>
    );
  }
}
