import { dataModelObjectName } from './LineageHelper.js';

test('with parentObject', () => {
  let dataModelObject = {
    name: 'Test',
    parentObject: {
      name: 'Parent_name'
    }
  }
  expect(dataModelObjectName(dataModelObject)).toBe('Parent_name.Test');
});

test('without parentObject', () => {
  let dataModelObject = {
    name: 'Test'
  }
  expect(dataModelObjectName(dataModelObject)).toBe('Test');
});
