import DMCTargetErpSystem from '../DataMappings/DMCTargetErpSystem';
import DataItemFilterComponent from './DataItemFilterComponent';
import DMCNewDataItemComponent from '../DataMappings/DMCNewDataItemComponent';
import DataItemTableComponent from './DataItemTableComponent';
import DataItemForm from './DataItemForm';

class DataItemControlComponent extends React.Component {
  constructor(props) {
    super(props);
    const { dataItems } = props;
    const originalDataItems = dataItems.map(item => ({ ...item }));
    this.state = {
      ...props,
      filterType: props.filterType,
      errors: [],
      changeDataItemFilterValue: this.changeDataItemFilterValue.bind(this),
      openNewDataItemForm: this.openNewDataItemForm.bind(this),
      createNewDataItem: this.createNewDataItem.bind(this),
      onDataItemModified: this.onDataItemModified.bind(this),
      onDataItemRemoved: this.onDataItemRemoved.bind(this),
      onDropSchemaObjectToDataItem: this.onDropSchemaObjectToDataItem.bind(this),
      onDropTermToDataItem: this.onDropTermToDataItem.bind(this),
      targetSystemEditMode: false,
      refreshDataItems: this.refreshDataItems.bind(this),
      originalDataItems,
      canAddNewDataItem: true,
      setCanAddNewDataItem: this.setCanAddNewDataItem.bind(this)
    };
    this.resetDataItem = this.resetDataItem.bind(this);
    this.onDataItemCreate = this.onDataItemCreate.bind(this);
    this.onDataItemUpdated = this.onDataItemUpdated.bind(this);
    this.browseErpSystem = this.browseErpSystem.bind(this);
    this.browseLink = this.browseLink.bind(this);
    this.removeDataItem = this.removeDataItem.bind(this);
    this.getDataSchemaObject = this.getDataSchemaObject.bind(this);
    this.toggleTargetSystemEditMode = this.toggleTargetSystemEditMode.bind(this);
    this.onTargetSystemChange = this.onTargetSystemChange.bind(this);
    this.onTargetSystemCancel = this.onTargetSystemCancel.bind(this);
    this.onTargetSystemSave = this.onTargetSystemSave.bind(this);
    this.onTargetSystemRemove = this.onTargetSystemRemove.bind(this);
    this.findErpSystemById = this.findErpSystemById.bind(this);
    this.dataItemRemoveMessage = this.dataItemRemoveMessage.bind(this);
    this.restoreOriginalDataItems = this.restoreOriginalDataItems.bind(this);
  }

  changeDataItemFilterValue(e) {
    e.preventDefault();
    const dataItemFilterValue = e.target.value;
    this.setState({
      filterType: dataItemFilterValue
    });
  }

  onDropTermToDataItem(termId) {
    const { canAddNewDataItem } = this.state;
    if (!canAddNewDataItem)
      return;

    const term = this.state.terms.find(term => term.id === termId);
    const report_item = this.createNewDataItem();
    report_item.data_model_object_type = 'Term';
    report_item.object_label = term.name;
    report_item.term_id = termId;

    this.setState({ report_item });
  }

  onDropSchemaObjectToDataItem(e) {
    const { canAddNewDataItem } = this.state;
    if (!canAddNewDataItem)
      return;

    e.preventDefault();
    DCB.html5droppableHighlight.drop();

    let schemaObject = JSON.parse(JSON.parse(e.dataTransfer.getData('text')).schemaObject);

    schemaObject = this.getDataSchemaObject(schemaObject);

    const report_item = this.createNewDataItem();
    report_item.data_model_object_type = `DataModel${schemaObject.className}`;
    report_item.data_model_object_id = schemaObject.id;
    report_item.object_label = schemaObject.name;
    report_item.data_model_object = schemaObject;

    this.setState({
      report_item
    });
  }

  getDataSchemaObject(schemaObject) {
    let changedSchemaObject;
    if (schemaObject.class_name === 'Column' || schemaObject.class_name === 'Index') {
      const erpSystem = schemaObject.parent_object.data_schema_version.data_schema.erp_system;
      const erpSystemId = erpSystem.id;
      const erpSystemName = erpSystem.name;
      changedSchemaObject =
        {
          ...schemaObject,
          className: schemaObject.class_name,
          parentObject: {
            dataSchemaVersion:
              {
                dataSchema:
                  {
                    erpSystem:
                      {
                        id: erpSystemId,
                        name: erpSystemName
                      }
                  }
              }
          },
          relatedTerm: schemaObject.related_term
        };
    } else {
      const erpSystem = schemaObject.data_schema_version.data_schema.erp_system;
      changedSchemaObject =
        {
          ...schemaObject,
          className: schemaObject.class_name,
          parentObject: {
            dataSchemaVersion:
              {
                dataSchema:
                  {
                    erpSystem:
                      {
                        id: erpSystem.id,
                        name: erpSystem.name
                      }
                  }
              }
          },
          dataModelObjectId: schemaObject.id,
          relatedTerm: schemaObject.related_term
        };
    }
    return changedSchemaObject;
  }

  browseErpSystem(e, erpSystem) {
    e.preventDefault();
    const additional_data = {
      erpSystemId: erpSystem.id,
      button: { href: '/institution/data_systems/search' },
      erpSystemChangeAllowed: false
    };
    SchemaSearch.search(additional_data);
  }

  browseLink(erpSystem) {
    if (this.props.onlyLinio && !this.props.technicalDefinitionView)
      return '';

    return <a href="#" onClick={e => this.browseErpSystem(e, erpSystem)}>Browse</a>;
  }

  toggleTargetSystemEditMode(e) {
    e && e.preventDefault();
    this.setState({ targetSystemEditMode: !this.state.targetSystemEditMode });
  }

  onTargetSystemChange(e) {
    const targetErpSystem = e.target ? this.findErpSystemById(e.target.value) : this.findErpSystemById(e.value);
    this.setState({ targetErpSystem });
  }

  findErpSystemById(id) {
    const { erpSystems } = this.state;

    return erpSystems
      .filter(erpSystem => erpSystem.groupFlag)
      .reduce((memo, obj) =>
        memo.concat(obj.childSystems), []).concat(erpSystems.filter(erpSystem => !erpSystem.groupFlag))
      .find(erpSystem => erpSystem.id === +id);
  }

  setCanAddNewDataItem(canAddNewDataItem) {
    this.setState({
      canAddNewDataItem
    });
  }

  onTargetSystemSave(e) {
    e.preventDefault();
    const { primaryId, primaryObjectId, targetErpSystem } = this.state;
    this.setState({
      targetSystemEditMode: false,
      targetErpSystem
    });
    const data = {
      report_version: {
        target_erp_system_id: targetErpSystem ? targetErpSystem.id : ''
      }
    };
    $j.ajax({
      url: `/institution/reports/${primaryId}` +
      `/versions/${primaryObjectId}/target_erp_system`,
      method: 'PATCH',
      data
    });
  }

  onTargetSystemRemove(e) {
    e.preventDefault();

    const { primaryId, primaryObjectId } = this.state;

    this.setState({
      targetSystemEditMode: false,
      targetErpSystem: null,
      targetSystem: null
    });

    const data = {
      report_version: {
        target_erp_system_id: ''
      }
    };

    $j.ajax({
      url: `/institution/reports/${primaryId}` +
      `/versions/${primaryObjectId}/target_erp_system`,
      method: 'PATCH',
      data
    });
  }

  onTargetSystemCancel(e) {
    e.preventDefault();
    this.setState({
      targetErpSystem: this.props.targetErpSystem,
      targetSystemEditMode: false
    });
  }

  getDefaultSourceSystemId() {
    const { reportVersionErpSystemIds } = this.props;
    if (reportVersionErpSystemIds.length === 1)
      return reportVersionErpSystemIds[0];

    return '';
  }

  openNewDataItemForm(e) {
    e.preventDefault();
    const { canAddNewDataItem } = this.state;
    if (!canAddNewDataItem) {
      jAlert('Error', 'Creating New Items is disabled in the ordering mode.');
      return;
    }
    const report_item = this.createNewDataItem();
    const sourceErpSystemId = report_item.erp_system_id || report_item.erpSystemId;
    const newReportItem = !report_item.id;
    if (newReportItem && !sourceErpSystemId)
      report_item.erp_system_id = this.getDefaultSourceSystemId();

    this.setState({ report_item });
  }

  createNewDataItem() {
    return {
      report_version_id: this.props.reportVersion.id
    };
  }

  onDataItemModified(dataItem) {
    this.setState({
      report_item: dataItem
    });
  }

  onDataItemRemoved(dataItem) {
    this.removeDataItem(dataItem);
  }

  resetDataItem() {
    this.setState({ report_item: null });
  }

  removeDataItem(dataItem) {
    const reportItem = {
      ...dataItem
    };

    jConfirm('Are you sure?', this.dataItemRemoveMessage(dataItem), () => {
      $j.ajax({
        url: `${this.getControllerUrl()}/${dataItem.id}`,
        method: 'DELETE',
        data: reportItem,
        success: (data) => {
          const { dataItems } = data;
          if (dataItems) {
            const originalDataItems = dataItems.map(item => ({ ...item }));
            this.setState({
              dataItems,
              originalDataItems,
              errors: []
            });
            this.hideButtons();
          }
        }
      })
        .error((xhr) => {
          if (xhr.responseJSON.errors)
            this.setState({ errors: xhr.responseJSON.errors });
          else
            location.reload();
        });
    });
  }

  hideDeleteAllButton() {
    let deleteAllItemsBtn = $j('.delete-lineages-container');
    if(!deleteAllItemsBtn.hasClass('hide')) {
      deleteAllItemsBtn.addClass('hide');
    }
  }

  hideExportButton() {
    let exportBtn = $j('.export-lineages');
    if(!exportBtn.hasClass('hide')) {
      exportBtn.addClass('hide');
    }
  }

  hideButtons() {
    if(_.isEmpty(this.state.dataItems)) {
      this.hideDeleteAllButton();
      this.hideExportButton();
    }
  }

  dataItemRemoveMessage(reportItem) {
    const { visibleTabs } = this.props;

    const { dataItemsWillBeDeleted, willBeDeletedOnOtherTabs } = this.props.messages
    const usedTabs = [];
    if (reportItem && reportItem.reportDisplayItem && visibleTabs.includes('Display'))
      usedTabs.push('Display');

    if (reportItem && reportItem.reportFilterCriteria.length > 0 && visibleTabs.includes('Selections'))
      usedTabs.push('Selections');

    if (reportItem && reportItem.reportSortCriteria.length > 0 && visibleTabs.includes('Sort Criteria'))
      usedTabs.push('Sort Criteria');

    if (reportItem && reportItem.lineageTargetId && visibleTabs.includes('Mapping Tab'))
      usedTabs.push('Mapping Tab');

    const message = usedTabs.length > 0 ? `${willBeDeletedOnOtherTabs} ${usedTabs.join(', ')}.` : dataItemsWillBeDeleted;
    return message;
  }

  refreshDataItems(dis, sort = true, refreshOriginals = true) {
    const { dataItems } = this.state;
    const reorderedItems = dataItems.map((itm) => {
      const { position } = dis.find(di => (di.id === itm.id));
      return Object.assign({}, itm, { position });
    });
    sort && reorderedItems.sort((i1, i2) => (i1.position - i2.position));
    if (refreshOriginals) {
      const originalDataItems = reorderedItems.map(item => ({ ...item }));
      this.setState({
        dataItems: reorderedItems,
        originalDataItems
      });
    } else {
      this.setState({
        dataItems: reorderedItems
      });
    }
  }

  getControllerUrl() {
    let url = window.location.href;
    if (url.indexOf('/versions') === -1)
      url = `${url.substr(0, url.indexOf('/data_items'))}/versions/${this.props.reportVersion.id}/report_items`;
    else
      url = 'report_items';

    return url;
  }

  onDataItemCreate(dataItem) {
    const { dataItems, originalDataItems } = this.state;
    dataItems.push(dataItem);
    originalDataItems.push({ ...dataItem });
  }

  onDataItemUpdated(dataItem) {
    let { dataItems } = this.state;
    dataItems = dataItems.map(di => (di.id == dataItem.id ? dataItem : di));
    const originalDataItems = dataItems.map(item => ({ ...item }));
    this.setState({
      dataItems,
      originalDataItems
    });
  }

  restoreOriginalDataItems() {
    const { originalDataItems } = this.state;
    const dataItems = originalDataItems.map(item => ({ ...item }));
    this.setState({
      dataItems
    });
  }

  render() {
    const { withTargetErpSystem, reportTemplate } = this.props;
    const { targetErpSystem } = this.state;
    const targetErpSystemId = (targetErpSystem && targetErpSystem.id) || '';
    const { editable } = this.props;
    const { terms } = this.props;
    return (
      <div>
        { !this.props.readOnly &&
        <DataItemFilterComponent
          {...this.props}
          {...this.state}
        /> }
        { withTargetErpSystem && reportTemplate?.code === 'ETL' && editable &&
        <div className="data-items-target-erp-system">
          <DMCTargetErpSystem
            key="DMCTargetErpSystem"
            browseLink={this.browseLink}
            toggleTargetSystemEditMode={this.toggleTargetSystemEditMode}
            targetSystemEditMode={this.state.targetSystemEditMode}
            editable={this.props.editable}
            onTargetSystemChange={this.onTargetSystemChange}
            onTargetSystemSave={this.onTargetSystemSave}
            onTargetSystemRemove={this.onTargetSystemRemove}
            onTargetSystemCancel={this.onTargetSystemCancel}
            targetErpSystem={this.state.targetErpSystem}
            helpPoints={this.props.helpPoints}
            erpSystems={this.props.erpSystems}
            imageEdit={this.props.imageEdit}
          />
        </div>}
        { editable &&
        <DMCNewDataItemComponent
          key="DMCNewDataItemComponent"
          helpPoints={this.props.helpPoints}
          {...this.state}
        />
        }
        { editable &&
          <DataItemForm
            key="DataItemForm"
            reportItem={this.state.report_item}
            onDataItemModified={this.state.onDataItemModified}
            embedded={false}
            onDataItemCreate={this.onDataItemCreate}
            onDataItemUpdated={this.onDataItemUpdated}
            onCancel={this.resetDataItem}
            uniqueTerms={terms}
            {...this.props}
            targetErpSystem={targetErpSystemId}
          />
        }
        { <Errors errors={this.state.errors} /> }
        <DataItemTableComponent
          {...this.props}
          {...this.state}
          restoreOriginalDataItems={this.restoreOriginalDataItems}
          controllerUrl={this.getControllerUrl()}
        />
      </div>
    );
  }
}

export default DataItemControlComponent;
