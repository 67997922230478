import PropTypes from 'prop-types';
import SpecDisplayDataItemForm from './SpecDisplayDataItemForm';
import SpecNewDisplayDataItem from './SpecNewDisplayDataItem';
import SpecDisplayDataItem from './SpecDisplayDataItem';
import HelpPoint from '../../Helpers/HelpPoint';

export default class SpecReportDisplayItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      displayDataItemForm: false,
      displayDataItemUpdateFlag: false,
      dragAndDropFlag: false,
      errors: [],
      toggleDisplayDataItemFormMode: this.toggleDisplayDataItemFormMode.bind(this),
      displayDataItemFormCancel: this.displayDataItemFormCancel.bind(this),
      createReportDisplayItem: this.createReportDisplayItem.bind(this),
      updateReportDisplayItem: this.updateReportDisplayItem.bind(this),
      onDropTermToReportDisplayItem: this.onDropTermToReportDisplayItem.bind(this),
      onDisplayDataItemRemoved: this.onDisplayDataItemRemoved.bind(this),
      onDisplayDataItemEdited: this.onDisplayDataItemEdited.bind(this),
      removeDisplayDataItem: this.removeDisplayDataItem.bind(this),
      handleDisplayDataItemExpandMode: this.handleDisplayDataItemExpandMode.bind(this)
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      reportDisplayItems: nextProps.reportDisplayItems
    });
  }

  onDisplayDataItemEdited(reportDisplayItem) {
    this.setState({
      reportDisplayItem,
      reportItem: reportDisplayItem.reportItem,
      displayDataItemUpdateFlag: true,
      displayDataItemForm: true,
      dragAndDropFlag: false,
      errors: []
    });
  }

  onDisplayDataItemRemoved(reportDisplayItem) {
    this.removeDisplayDataItem(reportDisplayItem);
  }

  onDropTermToReportDisplayItem(termId) {
    const term = this.props.terms.find(t => (t.id === termId));
    if (term) {
      const { reportItem } = this.props;
      reportItem.objectLabel = term.name;
      reportItem.termId = term.id;

      this.setState({
        reportItem,
        displayDataItemForm: true,
        dragAndDropFlag: true
      });
    }
  }

  getDefaultErpSystem() {
    const { reportVersionErpSystemIds } = this.props;
    if (reportVersionErpSystemIds.length === 1)
      return reportVersionErpSystemIds[0];

    return '';
  }

  removeDisplayDataItem(reportDisplayItem) {
    const { report, reportVersion } = this.props;
    const { reportDisplayItems } = this.state;

    const data = {
      report_display_item: {
        id: reportDisplayItem.id,
        report_version_id: reportDisplayItem.reportVersionId,
        notes: reportDisplayItem.notes,
        location: reportDisplayItem.location,
        aggregate_notes: reportDisplayItem.aggregateNotes,
        report_item_id: reportDisplayItem.reportItemId,
        report_item: {
          id: reportDisplayItem.reportItem.id,
          term_id: reportDisplayItem.reportItem.termId,
          erp_system_id: reportDisplayItem.reportItem.erpSystemId,
          object_label: reportDisplayItem.reportItem.objectLabel
        }
      }
    };

    const url = `/institution/reports/${report.id}/versions/${reportVersion.id}/report_display_items/${reportDisplayItem.id}`;

    $j.ajax({
      url,
      method: 'DELETE',
      data,
      success: (response) => {
        const newReportDisplayItems = reportDisplayItems.filter(item =>
          (item.id !== response.reportDisplayItem.id));
        this.props.onRemoveDisplayDataItem(newReportDisplayItems);
      }
    }).error((xhr) => {
      if (xhr?.responseJSON?.errors) {
        console.error(xhr.responseJSON.errors);
        this.setState({ errors: xhr.responseJSON.errors });
      } else
        window.location.reload();
    });
  }

  toggleDisplayDataItemFormMode(e) {
    e && e.preventDefault();

    const { reportItem } = this.state;
    if ((!reportItem.id && !reportItem.erpSystemId)) {
      const defaultSourceErpSystemId = this.getDefaultErpSystem();
      reportItem.erpSystemId = defaultSourceErpSystemId;
    }
    this.setState({
      displayDataItemForm: true,
      errors: [],
      reportItem
    });
  }

  displayDataItemFormCancel(e) {
    e && e.preventDefault();

    const reportItem = {
      createdAt: null,
      dataModelObjectId: null,
      dataModelObjectType: null,
      erpSystemId: null,
      id: null,
      lineageTargetId: null,
      objectLabel: null,
      position: null,
      reportVersionId: this.props.reportVersion.id,
      termId: null,
      updatedAt: null
    };

    const reportDisplayItem = {
      aggregateFlag: false,
      aggregateNotes: null,
      createdAt: null,
      id: null,
      location: null,
      notes: null,
      order: null,
      reportItemId: null,
      reportVersionId: this.props.reportVersion.id,
      updatedAt: null
    };

    this.setState({
      reportDisplayItem,
      reportItem,
      displayDataItemForm: false,
      displayDataItemUpdateFlag: false,
      dragAndDropFlag: false
    });
  }

  updateReportDisplayItem(reportItem, reportDisplayItem) {
    const { report, reportVersion } = this.props;

    const data = {
      report_display_item: {
        id: reportDisplayItem.id,
        report_version_id: reportDisplayItem.reportVersionId,
        notes: reportDisplayItem.notes,
        location: reportDisplayItem.location,
        aggregate_flag: reportDisplayItem.aggregateFlag,
        aggregate_notes: reportDisplayItem.aggregateNotes,
        report_item_id: reportDisplayItem.reportItemId,
        report_item: {
          id: reportItem.id,
          term_id: reportItem.termId,
          erp_system_id: reportItem.erpSystemId,
          object_label: reportItem.objectLabel
        },
        left_pos: reportDisplayItem.leftPos,
        top_pos: reportDisplayItem.topPos
      }
    };

    const url = `/institution/reports/${report.id}/versions/${reportVersion.id}/report_display_items/${reportDisplayItem.id}`;
    const self = this;
    $j.ajax({
      url,
      method: 'PATCH',
      data,
      success: (response) => {
        let { reportDisplayItems } = self.state;
        reportDisplayItems = reportDisplayItems.map(rdi =>
          (rdi.id === response.reportDisplayItem.id ? response.reportDisplayItem : rdi));
        self.setState({
          reportDisplayItems,
          displayDataItemForm: false,
          displayDataItemUpdateFlag: false,
          dragAndDropFlag: false,
          errors: [],
          reportItem: {}
        });
        this.props.onUpdateDisplayDataItem(reportDisplayItems);
      }
    }).error((xhr) => {
      if (xhr?.responseJSON?.errors) {
        console.error(xhr.responseJSON.errors);
        self.setState({ errors: xhr.responseJSON.errors, reportItem });
      } else
        window.location.reload();
    });
  }

  createReportDisplayItem(reportItem, reportDisplayItem) {
    const { report, reportVersion } = this.props;
    const data = {
      report_display_item: {
        report_version_id: reportDisplayItem.reportVersionId,
        notes: reportDisplayItem.notes,
        location: reportDisplayItem.location,
        aggregate_flag: reportDisplayItem.aggregateFlag,
        aggregate_notes: reportDisplayItem.aggregateNotes,
        report_item_id: reportDisplayItem.reportItemId,
        report_item: {
          id: reportItem.id,
          term_id: reportItem.termId,
          erp_system_id: reportItem.erpSystemId,
          object_label: reportItem.objectLabel
        }
      }
    };

    const url = `/institution/reports/${report.id}/versions/${reportVersion.id}/report_display_items`;
    const self = this;

    $j.ajax({
      url,
      method: 'POST',
      data,
      success: (response) => {
        const { reportDisplayItems } = self.state;
        reportDisplayItems.push(response.reportDisplayItem);
        self.setState({
          reportDisplayItems,
          displayDataItemForm: false,
          dragAndDropFlag: false,
          displayDataItemUpdateFlag: false,
          errors: [],
          reportItem: {}
        });
      }
    }).error((xhr) => {
      if (xhr?.responseJSON?.errors) {
        console.error(xhr.responseJSON.errors);
        this.setState({ errors: xhr.responseJSON.errors, reportItem });
      } else
        window.location.reload();
    });
  }

  handleDisplayDataItemExpandMode() {
    this.props.toggleDisplayDataItemExpandMode();
  }

  render() {
    const {
      reportDisplayItems,
      handleDisplayDataItemExpandMode
    } = this.state;
    const {
      isDisplayDataItemsExpanded,
      emptyReportDisplayItem,
      emptyReportItem,
      editIconPath,
      plusImageSrc,
      editable,
      expandable,
      userCanEditTerms,
      newTermPath
    } = this.props;

    const displayDataItemsClass = `display-data-items ${isDisplayDataItemsExpanded ? 'display-data-items__expanded' : ''}`;
    const displayDataItemsInnerClass = `display-data-items__list-container ${isDisplayDataItemsExpanded ? 'display-data-items__expanded' : ''}`;
    const sidebarToggleAreaClass = `span-1
      sidebar-toggle-area-workflow
      sidebar-toggle-area-workflow_margin
      ${isDisplayDataItemsExpanded && 'rotate'}`;

    return (
      <div className={displayDataItemsClass}>
        <div className={displayDataItemsInnerClass} >
          <div>
            <SpecDisplayDataItemForm
              {...this.state}
              onCancel={this.state.displayDataItemFormCancel}
              helpPoints={this.props.helpPoints}
            />
          </div>
          <div className="display-data-items__new-item">
            {
              editable &&
              <SpecNewDisplayDataItem
                onDropTermToReportDisplayItem={this.state.onDropTermToReportDisplayItem}
                plusImageSrc={plusImageSrc}
                toggleDisplayDataItemFormMode={this.state.toggleDisplayDataItemFormMode}
                emptyReportDisplayItem={emptyReportDisplayItem}
                emptyReportItem={emptyReportItem}
                userCanEditTerms={userCanEditTerms}
                newTermPath={newTermPath}
                helpPoints={this.props.helpPoints}
              />
            }
          </div>
          <div className="display-data-items__list">
            <div className="display-data-items__header">
              Items on Display<HelpPoint helpPoint={this.props.helpPoints.point9} />
            </div>
            <div>
              <div>
                {
                  isDisplayDataItemsExpanded ? (
                    <div className="display-data-items__label-expanded">
                      <div className="display-data-items__item-name-label">Item</div>
                      <div className="display-data-items__display-notes-label">Display Notes</div>
                      <div className="display-data-items__aggregate-label">Aggregate</div>
                      <div className="display-data-items__aggregate-notes-label">Aggregate Notes</div>
                      <div className="display-data-items__location-label">Location</div>
                    </div>
                  ) : (
                    <div className="display-data-items__label">Item</div>
                  )
                }
                <div style={{ maxHeight: '940px' }}>
                  <div
                    style={{ overflowY: 'auto', minHeight: '250px' }}
                    className="display-data-items__scroll-items"
                  >
                    {reportDisplayItems.map(reportDisplayItem =>
                      (<SpecDisplayDataItem
                        key={`ddi-${reportDisplayItem.id}`}
                        {...this.state}
                        isDisplayDataItemsExpanded={isDisplayDataItemsExpanded}
                        imageEdit={editIconPath}
                        reportDisplayItem={reportDisplayItem}
                      />))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
        {
          expandable &&
          <div
            id="sidebar-toggle-area"
            className={sidebarToggleAreaClass}
            onClick={handleDisplayDataItemExpandMode}
          />
        }
      </div>
    );
  }
}

SpecReportDisplayItems.propTypes = {
  toggleDisplayDataItemExpandMode: PropTypes.func,
  onRemoveDisplayDataItem: PropTypes.func,
  onUpdateDisplayDataItem: PropTypes.func,
  reportDisplayItems: PropTypes.arrayOf(Object).isRequired,
  terms: PropTypes.arrayOf(Object).isRequired,
  reportVersionErpSystemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  isDisplayDataItemsExpanded: PropTypes.bool,
  editable: PropTypes.bool.isRequired,
  expandable: PropTypes.bool,
  userCanEditTerms: PropTypes.bool,
  reportDisplayItem: PropTypes.object.isRequired,
  emptyReportDisplayItem: PropTypes.object,
  emptyReportItem: PropTypes.object,
  reportItem: PropTypes.object,
  helpPoints: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired,
  reportVersion: PropTypes.object.isRequired,
  editIconPath: PropTypes.string.isRequired,
  plusImageSrc: PropTypes.string.isRequired,
  newTermPath: PropTypes.string.isRequired
};

SpecReportDisplayItems.defaultProps = {
  toggleDisplayDataItemExpandMode: () => {},
  onRemoveDisplayDataItem: () => {},
  onUpdateDisplayDataItem: () => {},
  emptyReportDisplayItem: null,
  emptyReportItem: null,
  reportItem: null,
  expandable: false,
  userCanEditTerms: false,
  isDisplayDataItemsExpanded: false
};
