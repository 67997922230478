import HelpPoint from '../Helpers/HelpPoint';

class DMCNewLineage extends React.Component {
  constructor(props) {
    super(props);
    this.openNewTermPopup = this.openNewTermPopup.bind(this);
  }

  componentDidMount() {
    $j(this.el).droppable({
      scope: 'omnifind',
      tolerance: 'touch',
      hoverClass: 'notice',
      drop: (_event, ui) => {
        const termId = ui.helper.data('dcb-term_id');

        const {
          onDropTermToLineage
        } = this.props;
        onDropTermToLineage(termId);
      }
    });
  }

  openNewTermPopup(e) {
    e.preventDefault();
    const { newTermPath } = this.props;
    open_new_definition_modalbox_for_template(newTermPath);
    return false;
  }

  render() {
    const {
      onDropSchemaObjectToLineage,
      openNewLineageForm,
      plusImageSrc,
      userCanEditTerms
    } = this.props;

    return (
      <div
        className="dmc-new-lineage dmc-new-lineage-etl html5-droppable-highlight"
        onDragOver={e => e.preventDefault()}
        onDrop={onDropSchemaObjectToLineage}
        ref={el => this.el = el}
      >
        <span style={{ fontWeight: 'bold' }}>Add a Target Item: <HelpPoint helpPoint={this.props.helpPoints.point15} /></span>
        <br />
        <div style={{ verticalAlign: 'middle', textAlign: 'left', marginLeft: '54px' }}>
          <a
            href=""
            onClick={openNewLineageForm}
            className="dmc-new-lineage__link"
          >
            <img
              src={plusImageSrc}
              className="new-display-data-item__plus-img"
              alt="Add display item icon"
            />
            Existing Definition 
            <HelpPoint helpPoint={this.props.helpPoints.point16} />
          </a>
        </div>
        {
          userCanEditTerms &&
          <div style={{ verticalAlign: 'middle', textAlign: 'left', marginLeft: '54px' }}>
            <a
              onClick={this.openNewTermPopup}
              className="new-display-data-item__link"
              href=""
            >
              <img
                className="new-display-data-item__plus-img"
                src={plusImageSrc}
                alt="Add display item icon"
              />
              New Definition 
              <HelpPoint helpPoint={this.props.helpPoints.point17} />
            </a>
          </div>
        }
      </div>
    );
  }
}

export default DMCNewLineage;
