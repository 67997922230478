import HelpPoint from '../Helpers/HelpPoint';

class DMCNewDataItemComponent extends React.Component {
  constructor(props) {
    super(props);
    this.openNewTermPopup = this.openNewTermPopup.bind(this);
  }

  componentDidMount() {
    $j(this.el).droppable({
      scope: 'omnifind',
      tolerance: 'touch',
      hoverClass: 'notice',
      drop: (_event, ui) => {
        const termId = ui.helper.data('dcb-term_id');

        const {
          onDropTermToDataItem
        } = this.props;

        onDropTermToDataItem(termId);
      }
    });
  }

  openNewTermPopup(e) {
    e.preventDefault();
    const { newTermPath } = this.props;
    open_new_definition_modalbox_for_template(newTermPath);
    return false;
  }

  render() {
    const {
      onDropSchemaObjectToDataItem,
      openNewDataItemForm,
      plusImageSrc,
      userCanEditTerms
    } = this.props;

    return (
      <div
        className="dmc-new-lineage html5-droppable-highlight"
        onDragOver={e => e.preventDefault()}
        onDrop={onDropSchemaObjectToDataItem}
        ref={el => this.el = el}
      >
        <div className="new-display-data-item__title">Add an Item: <HelpPoint helpPoint={this.props.helpPoints.point10} /></div>
        <div>
          <a
            href="#"
            onClick={openNewDataItemForm}
            className="dmc-new-lineage__link"
          >
            <img
              src={plusImageSrc}
              className="dmc-new-lineage__plus-icon"
            />
            <span>Existing Definition</span>
          </a>
          <HelpPoint helpPoint={this.props.helpPoints.point11} />
        </div>
        {
          userCanEditTerms &&
          <div>
            <a
              href=""
              onClick={this.openNewTermPopup}
              className="dmc-new-lineage__link"
            >
              <img
                src={plusImageSrc}
                className="dmc-new-lineage__plus-icon"
              />
              <span>New Definition</span>
            </a>
            <HelpPoint helpPoint={this.props.helpPoints.point12} />
          </div>
        }
      </div>
    );
  }
}

export default DMCNewDataItemComponent;
