import { Errors } from '../../Lineage/Errors';
import OptimizedSelect from '../../shared/OptimizedSelect';
import HelpPoint from '../../Helpers/HelpPoint';

export default class SpecDisplayDataItemForm extends React.Component {
  // TODO: fix behaviour of selectors on this form!
  constructor(props) {
    super(props);
    this.handleTermsSelectChange = this.handleTermsSelectChange.bind(this);
    this.handleReportItemsSelectChange = this.handleReportItemsSelectChange.bind(this);
    this.handleCreateReportDisplayItem = this.handleCreateReportDisplayItem.bind(this);
    this.handleUpdateReportDisplayItem = this.handleUpdateReportDisplayItem.bind(this);
    this.handleTermSelectClean = this.handleTermSelectClean.bind(this);
    this.hadleAggregateFlagChange = this.hadleAggregateFlagChange.bind(this);
    this.state = {
      ...props,
      dataSent: false
    };
  }


  componentWillReceiveProps(nextProps) {
    if (this.props.displayDataItemForm === true && nextProps.displayDataItemForm === false) {
      const reportItem = {
        createdAt: null,
        dataModelObjectId: null,
        dataModelObjectType: null,
        erpSystemId: nextProps.erpSystemId,
        id: null,
        lineageTargetId: null,
        objectLabel: null,
        position: null,
        reportVersionId: nextProps.reportItem.reportVersionId,
        termId: null,
        updatedAt: null
      };

      const reportDisplayItem = {
        aggregateFlag: false,
        aggregateNotes: null,
        createdAt: null,
        id: null,
        location: null,
        notes: null,
        order: null,
        reportItemId: null,
        reportVersionId: nextProps.reportDisplayItem.reportVersionId,
        updatedAt: null
      };

      this.setState({
        reportItem,
        reportDisplayItem,
        displayDataItemUpdateFlag: nextProps.displayDataItemUpdateFlag,
        errors: []
      });
    } else if (nextProps.reportItem && nextProps.dragAndDropFlag) {
      this.setState({
        reportItem: nextProps.reportItem || {},
        errors: []
      });
    } else if (nextProps.displayDataItemUpdateFlag) {
      this.setState({
        reportDisplayItem: nextProps.reportDisplayItem,
        reportItem: nextProps.reportItem,
        displayDataItemUpdateFlag: nextProps.displayDataItemUpdateFlag,
        errors: []
      });
    } else if (nextProps.reportItem) {
      this.setState({
        reportItem: nextProps.reportItem || {},
        errors: []
      });
    }
  }

  UNSAFE_componentWillUpdate(nextProps, _nextState) {
    if (nextProps.displayDataItemForm === false) {
      $j(this.el).dialog('destroy');
    }
  }

  componentDidUpdate(_prevProps, _prevState) {
    const currentEl = $j(this.el);
    let opened;

    try {
      opened = currentEl.dialog('isOpen');
    } catch (_) {}

    if (this.props.errors.length > 0 && this.state.dataSent) {
      this.setState({
        dataSent: false
      }, () => currentEl.animate({ scrollTop: 0 }));
    }

    if (!opened) {
      currentEl.dialog({
        modal: true,
        resizable: false,
        height: 700,
        width: 550,
        close: this.props.onCancel,
        create: function() {
          let titleHelpPoint = currentEl.closest('.ui-dialog').find('.add-display-item-modal');
          let dialogTitle = currentEl.closest('.ui-dialog').find('.ui-dialog-title');
          dialogTitle.append(titleHelpPoint.html());
          titleHelpPoint.remove();
        }
      });
    }
  }

  handleTermSelectClean(e) {
    const inputText = e.target.value;
    if (inputText === null || inputText.length === 0)
      this.handleTermsSelectChange(e);
  }

  handleCreateReportDisplayItem(e) {
    const { reportItem, reportDisplayItem } = this.state;
    this.setState({
      dataSent: true
    }, () => this.props.createReportDisplayItem(reportItem, reportDisplayItem));
  }

  handleUpdateReportDisplayItem(e) {
    const { reportItem, reportDisplayItem } = this.state;
    this.setState({
      dataSent: true
    }, () => this.props.updateReportDisplayItem(reportItem, reportDisplayItem));
  }

  handleReportItemsSelectChange(selectedOption) {
    const { reportItems } = this.props;
    const value = selectedOption ? selectedOption.value : '';
    let reportItem = reportItems.find(ri => (ri.id == value));
    const { reportDisplayItem } = this.state;
    if (!reportItem) {
      reportItem = { ...this.state.reportItem };
      reportItem.id = null;
    }
    reportDisplayItem.reportItemId = reportItem.id;
    this.setState({
      reportDisplayItem,
      reportItem
    });
  }

  handleTermsSelectChange(selectedOption) {
    const { reportItem, terms } = this.state;
    const value = selectedOption ? selectedOption.value : '';
    const term = terms.find(trm => (trm.id === value));
    if (term) {
      reportItem.termId = term.id;
      reportItem.objectLabel = term.name;
    } else
      reportItem.termId = value;

    this.setState({
      reportItem
    });
  }

  handleObjectLabelChange(e) {
    const { reportItem } = this.state;

    reportItem.objectLabel = e.target.value;
    this.setState({
      reportItem
    });
  }

  handleObjectNotesChange(e) {
    const { reportDisplayItem } = this.state;

    reportDisplayItem.notes = e.target.value;
    this.setState({
      reportDisplayItem
    });
  }

  handleObjectAggregateNotesChange(e) {
    const { reportDisplayItem } = this.state;

    reportDisplayItem.aggregateNotes = e.target.value;
    this.setState({
      reportDisplayItem
    });
  }

  handleObjectLocationChange(e) {
    const { reportDisplayItem } = this.state;

    reportDisplayItem.location = e.target.value;
    this.setState({
      reportDisplayItem
    });
  }

  handleErpSystemChange(e) {
    const selectedErpSystemId = e.target.value;
    let reportItem;

    if (selectedErpSystemId)
      reportItem = this.state.reportItem;
    else
      reportItem = this.props.reportItem;


    reportItem.erpSystemId = e.target.value;
    this.setState({
      reportItem
    });
  }

  hadleAggregateFlagChange(e) {
    const { reportDisplayItem } = this.state;
    const { checked } = e.target;
    this.setState({
      reportDisplayItem: {
        ...reportDisplayItem,
        aggregateFlag: checked
      }
    });
  }

  render() {
    const {
      displayDataItemForm,
      reportItems
    } = this.props;

    const {
      reportDisplayItem,
      reportItem,
      displayDataItemUpdateFlag
    } = this.state;
    if (!displayDataItemForm)
      return null;


    const { terms, sourceErpSystems } = this.props;
    const title = displayDataItemUpdateFlag ? 'Update a Display Item' : 'Add a Display Item';
    const titleHelpPoint = displayDataItemUpdateFlag ?
      <HelpPoint helpPoint={this.props.helpPoints.point27} /> : <HelpPoint helpPoint={this.props.helpPoints.point17} />;
    const controls = displayDataItemUpdateFlag ? (
      <input
        className="f-l"
        type="submit"
        onClick={this.handleUpdateReportDisplayItem}
        value="Update"
      />
    ) : (
      <input
        className="f-l"
        type="submit"
        onClick={this.handleCreateReportDisplayItem}
        value="Save"
      />
    );

    const selectDILabel = displayDataItemUpdateFlag ?
      'Choose a New Data Item' : 'Select an Existing Data Item';
    const selectDIHelpPoint = displayDataItemUpdateFlag ?
      <HelpPoint helpPoint={this.props.helpPoints.point28} /> : <HelpPoint helpPoint={this.props.helpPoints.point18} />;
    const createNewDILabel = displayDataItemUpdateFlag ?
      'Or Edit the Existing Data Item' : 'Or Create a New Data Item';
    const createNewDIHelpPoint = displayDataItemUpdateFlag ?
      <HelpPoint helpPoint={this.props.helpPoints.point29} /> : <HelpPoint helpPoint={this.props.helpPoints.point19} />;
    const reportOptions = reportItems.map(ri => ({ value: ri.id, label: ri.objectLabel }));
    const reportSelectedOption = reportOptions.filter(option => option.value === reportItem.id);
    const termOptions = terms.map(trm => ({ value: trm.id, label: trm.name }));
    const termSelectedOption = termOptions.filter(option => option.value === reportItem.termId);

    return (
      <div
        title={title}
        ref={el => this.el = el}
      >
        <span className='add-display-item-modal'>{titleHelpPoint}</span>
        <Errors errors={this.props.errors} />
        <span id="report-items-select-span">
          <label className="font-110">{selectDILabel}: {selectDIHelpPoint}</label>
          <OptimizedSelect
            options={reportOptions}
            value={reportSelectedOption}
            onChange={this.handleReportItemsSelectChange}
          />
        </span>
        <hr className="prepend-top" />
        <span id="terms-select-span">
          <label className="font-110">{createNewDILabel}: {createNewDIHelpPoint}</label>
          <label key="label-definition">Definition: <HelpPoint helpPoint={this.props.helpPoints.point20} /></label>
          <OptimizedSelect
            options={termOptions}
            value={termSelectedOption}
            onChange={this.handleTermsSelectChange}
          />
        </span>
        <br />
        <label key="label-label">Label: <HelpPoint helpPoint={this.props.helpPoints.point21} /></label>
        <input
          type="text"
          value={reportItem.objectLabel ? reportItem.objectLabel : ''}
          onChange={e => this.handleObjectLabelChange(e)}
        />
        <br />
        <label>Source Data System: <HelpPoint helpPoint={this.props.helpPoints.point22} /></label>
        <select
          id="erp-system-select"
          value={reportItem.erpSystemId || ''}
          onChange={e => this.handleErpSystemChange(e)}
        >
          <option value="">
            -Choose Data System-
          </option>
          {
            sourceErpSystems.findAll(erp => (erp.childSystems && erp.childSystems.length > 0))
              .map(erp => (
                <optgroup
                  key={erp.id}
                  value={erp.id}
                  label={erp.name}
                >
                  {erp.childSystems.map(childErp =>
                    (<option
                      key={childErp.id}
                      value={childErp.id}
                    >
                      {childErp.name}
                     </option>))}
                </optgroup>
              ))
          }
          {
            this.props.sourceErpSystems.findAll(erp => (!erp.groupFlag))
              .map(erp => (
                <option
                  key={erp.id}
                  value={erp.id}
                  label={erp.name}
                >
                  {erp.name}
                </option>
              ))
          }
        </select>
        <label key="label-notes">Display Notes: <HelpPoint helpPoint={this.props.helpPoints.point23} /></label>
        <textarea
          rows="5"
          cols="50"
          value={reportDisplayItem.notes || ''}
          onChange={e => this.handleObjectNotesChange(e)}
        />
        <br />
        <label key="label-aggregate-flag">Aggregate: <HelpPoint helpPoint={this.props.helpPoints.point24} /></label>
        <input
          type="checkbox"
          name="aggregate-flag"
          checked={reportDisplayItem.aggregateFlag}
          onChange={e => this.hadleAggregateFlagChange(e)}
        />
        <label key="label-aggregate-notes">Aggregate Notes: <HelpPoint helpPoint={this.props.helpPoints.point25} /></label>
        <textarea
          rows="5"
          cols="50"
          value={reportDisplayItem.aggregateNotes ? reportDisplayItem.aggregateNotes : ''}
          onChange={e => this.handleObjectAggregateNotesChange(e)}
        />
        <br />
        <label key="label-location">Location: <HelpPoint helpPoint={this.props.helpPoints.point26} /></label>
        <input
          type="text"
          value={reportDisplayItem.location || ''}
          onChange={e => this.handleObjectLocationChange(e)}
        />
        <br />
        {controls}
      </div>
    );
  }
}
