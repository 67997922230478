import DataItemForm from './DataItemForm';
import DataItemNewComponent from './DataItemNewComponent';
import DataItemsAutocomplete from './DataItemsAutocomplete';

class DataItemsSelectorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.resetDataItem = this.resetDataItem.bind(this);
    this.onDataItemCreate = this.onDataItemCreate.bind(this);
    this.onDataItemUpdated = this.onDataItemUpdated.bind(this);
    this.openNewTermPopup = this.openNewTermPopup.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      ...props,
      onDropTermToDataItem: this.onDropTermToDataItem.bind(this),
      openNewDataItemForm: this.openNewDataItemForm.bind(this),
      onDataItemModified: this.onDataItemModified.bind(this),
      createdReportItemId: null
    };
  }

  onDropTermToDataItem(termId) {
  }

  openNewDataItemForm(e) {
    e.preventDefault();
    const reportItem = this.createNewDataItem();
    this.setState({ reportItem });
  }

  createNewDataItem() {
    const { reportVersion } = this.props;
    const { sourceErpSystemsIds } = this.props;
    let erpSystemId;
    if (sourceErpSystemsIds.length === 1)
      [erpSystemId] = sourceErpSystemsIds;

    return {
      report_version_id: reportVersion.id,
      erpSystemId,
      erp_system_id: erpSystemId
    };
  }

  onDataItemCreate(reportItem) {
    const { reportItems } = this.state;
    reportItems.push(reportItem);
    this.setState({
      createdReportItemId: reportItem.id
    });
  }

  onDataItemUpdated(dataItem) {
  }

  onDataItemModified(reportItem) {
    this.setState({
      reportItem
    });
  }

  resetDataItem() {
    this.setState({ reportItem: null });
  }

  openNewTermPopup(e) {
    e.preventDefault();
    const { newTermPath } = this.props;
    open_new_definition_modalbox_for_template(newTermPath);
    return false;
  }

  onSubmit(e) {
    e.preventDefault();
    $j('#create_creteria_button').click();
    this.setState({
      createdReportItemId: null
    });
  }

  render() {
    const {
      editable,
      terms,
      targetErpSystem,
      plusImageSrc,
      userCanEditTerms,
      showSubmitButton,
      submitButtonName,
      submitButtonId
    } = this.props;

    return (
      <div>
        <DataItemsAutocomplete
          {...this.state}
        />
        <span style={{ fontWeight: 'bold' }}>Add an Item:</span>
        <DataItemNewComponent {...this.state} />
        { editable &&
        <DataItemForm
          key="DataItemForm"
          reportItem={this.state.reportItem}
          onDataItemModified={this.state.onDataItemModified}
          targetErpSystem={targetErpSystem}
          embedded={false}
          onDataItemCreate={this.onDataItemCreate}
          onDataItemUpdated={this.onDataItemUpdated}
          onCancel={this.resetDataItem}
          uniqueTerms={terms}
          {...this.props}
        /> }
        {
          userCanEditTerms &&
          <div>
            <a
              onClick={this.openNewTermPopup}
              className="new-display-data-item__link"
              href=""
            >
              <img
                className="new-display-data-item__plus-img"
                src={plusImageSrc}
              />
              New Definition
            </a>
          </div>
        }
        {
          showSubmitButton &&
          <div style={{ marginTop: '20px' }}>
            <input
              type="submit"
              name="commit"
              value={submitButtonName}
              onClick={this.onSubmit}
            />
            <input
              id={submitButtonId}
              style={{ display: 'none' }}
              type="submit"
              name="commit"
              value="Hidden"
            />
          </div>
        }
      </div>
    );
  }
}

export default DataItemsSelectorComponent;
