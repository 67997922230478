import HelpPoint from '../Helpers/HelpPoint';
import { WikiLinksUpdateModal } from '../../../assets/javascripts/wiki_links/wikiLinksUpdateModal';

// Description Form on Functional Detail, Technical Detals, Mapping, Selections, Sort Criteria, Technical, Atttachments tabs
export default class TabSubjectDetailsDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      tabSubjectDetailsDescriptionContent: this.tabSubjectDetailsDescriptionContent.bind(this),
      errorNoticeContent: this.errorNoticeContent.bind(this),
      browseDataSystemButton: this.browseDataSystemButton.bind(this),
      getHelpPoint: this.getHelpPoint.bind(this),
      tabSubjectDetailsDescriptionElement: props.tabSubjectDetailsDescription,
      onTabSubjectDetailsDescriptionSave: this.onTabSubjectDetailsDescriptionSave.bind(this),
      onTabSubjectDetailsDescriptionCancel: this.onTabSubjectDetailsDescriptionCancel.bind(this),
      toggleTabSubjectDetailsDescriptionEditMode: this.toggleTabSubjectDetailsDescriptionEditMode.bind(this),
      onTabSubjectDetailsDescriptionChange: this.onTabSubjectDetailsDescriptionChange.bind(this),
      tabSubjectDetailsDescriptionEditMode: false,
      reportUpdateErrorMessage: '',
      tabSubjectDetailsEditDescription: props.tabSubjectDetailsEditDescription || props.tabSubjectDetailsDescription
    };
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  onUnload = (e) => {
    const displayDescriptionUpdated =
      this.state.tabSubjectDetailsEditDescription.toString() !== this.state.tabSubjectDetailsDescription.toString();

    if (this.state.tabSubjectDetailsDescriptionEditMode && displayDescriptionUpdated) {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  componentDidUpdate() {
    const { tabSubjectDetailsDescriptionEditMode } = this.state;
    if (tabSubjectDetailsDescriptionEditMode) {
      const { tabSubjectDetailsDescriptionId, body } = this.state;
      if (!CKEDITOR.instances[tabSubjectDetailsDescriptionId]) {
        CKEDITOR.replace(tabSubjectDetailsDescriptionId, { toolbar: 'mini' });

        const instance = CKEDITOR.instances[tabSubjectDetailsDescriptionId];
        instance.setData(body);
        instance.on('change', () => {
          const target = {
            name: `${tabSubjectDetailsDescriptionId}`,
            value: instance.getData()
          };

          this.state.onTabSubjectDetailsDescriptionChange({ target });
        });
      }
      initDataSchemaSearch();
    }
  }

  getWikiLinksData = () => {
    const oldNameAttributes = $j('.old-name-field').toArray();
    const newNameAttributes = $j('.new-name-field').toArray();
    const oldToolAttributes = $j('.old-tool-field').toArray();
    const newToolAttributes = $j('.new-tool-field').toArray();
    const typeAttributes = $j('.type-field').toArray();
    const missedSpecLinkAttributes = $j('.missed-spec-link').toArray();
    let linksData = oldNameAttributes.map((oldName, index) => ({
      old_name: oldName.value,
      new_name: newNameAttributes[index].value,
      old_tool: oldToolAttributes[index].value,
      new_tool: newToolAttributes[index].value,
      type: typeAttributes[index].value,
    }));
    missedSpecLinkAttributes.map((link) => {
      linksData.push({missing_link: link.value})
    })
    return linksData;
  };

  formSubmit(parentClass) {
    const {
      reportVersion,
      tabSubjectDetailsDescriptionField
    } = parentClass.state;
    const wikiLinkAttributes = parentClass.getWikiLinksData();
    const fieldValue = CKEDITOR.instances[this.state.tabSubjectDetailsDescriptionId].getData();
    const data = {
      report_version: {
        [parentClass.state.tabSubjectDetailsDescriptionField]: fieldValue,
        source_erp_system_ids: parentClass.state.reportVersionErpSystemIds,
        data_tool_ids: parentClass.state.dataToolIds,
        wiki_link_attributes: wikiLinkAttributes
      }
    };

    $j.ajax({
      url: `/institution/reports/${reportVersion.report_id}` +
      `/versions/${reportVersion.id}`,
      method: 'PATCH',
      data,
      success: (response) => {
        CKEDITOR.instances[parentClass.state.tabSubjectDetailsDescriptionId].destroy();
        parentClass.setState({
          tabSubjectDetailsDescriptionEditMode: false,
          tabSubjectDetailsDescriptionElement: response[tabSubjectDetailsDescriptionField],
          tabSubjectDetailsDescription: response[tabSubjectDetailsDescriptionField]
        }, () => {
          WikiLinksUpdateModal.addWikiLinksToStorage();
        });
      },
      error: (_jqXHR, _textStatus, errorThrown) => {
        parentClass.setState({
          tabSubjectDetailsDescription: fieldValue,
          reportUpdateErrorMessage: errorThrown
        }, () => {
          WikiLinksUpdateModal.addWikiLinksToStorage();
        });
      }
    });
  }

  onChangeCallback(parentClass, value) {
    parentClass.setState({ tabSubjectDetailsEditDescription: value });
  }

  onTabSubjectDetailsDescriptionSave(e) {
    e.preventDefault();
    WikiLinksUpdateModal.startSubmitModalsSequence(e, () => this.formSubmit(this));
  }

  onTabSubjectDetailsDescriptionCancel(e) {
    e.preventDefault();
    CKEDITOR.instances[this.state.tabSubjectDetailsDescriptionId].destroy();
    this.setState({
      tabSubjectDetailsEditDescription: this.state.tabSubjectDetailsDescriptionElement,
      tabSubjectDetailsDescriptionEditMode: false
    });
  }

  toggleTabSubjectDetailsDescriptionEditMode(e) {
    e.preventDefault();
    const instanceEditor = CKEDITOR.instances[this.state.tabSubjectDetailsDescriptionId];
    const { tabSubjectDetailsDescriptionEditMode } = this.state;
    if (instanceEditor)
      instanceEditor.destroy();

    this.setState({ tabSubjectDetailsDescriptionEditMode: !tabSubjectDetailsDescriptionEditMode });
  }

  onTabSubjectDetailsDescriptionChange(obj) {
    this.setState({ tabSubjectDetailsEditDescription: obj.target.value });
  }

  tabSubjectDetailsDescriptionContent() {
    const {
      tabSubjectDetailsDescription,
      tabSubjectDetailsEditDescription,
      tabSubjectDetailsDescriptionEditMode
    } = this.state;

    let el;

    if (tabSubjectDetailsDescriptionEditMode) {
      el = (
        <div id="tab-subject-details-desc-editable-area" className="schema-droppable details-section-form form-with-wiki-links-select-modal form-with-spec-type-modal">
          <textarea
            onChange={this.state.onTabSubjectDetailsDescriptionChange}
            value={tabSubjectDetailsEditDescription}
            id={this.state.tabSubjectDetailsDescriptionId}
          />

          <br />
          <div style={{ height: '50px' }}>
            <button
              className="pretty-button disableonclick med btn-entry-point"
              onClick={this.state.onTabSubjectDetailsDescriptionSave}
            >
              Save
            </button>
            <span style={{ margin: '0px 5px', float: 'left' }}>or</span>
            <button
              className="cancel-form-button"
              onClick={this.state.onTabSubjectDetailsDescriptionCancel}
              aria-expanded={tabSubjectDetailsDescriptionEditMode}
              aria-controls="tab-subject-details-desc-editable-area"
            >
              Cancel
            </button>
          </div>
        </div>
      );
    } else {
      el = (
        <p
          dangerouslySetInnerHTML={{
            __html: tabSubjectDetailsDescription || 'Not specified.'
          }}
        />
      );
    }
    return el;
  }

  allowBrowse() {
    return ['Selection Description', 'Sort Description'].includes(this.props.detailsLabel);
  }

  browseDataSystemButton() {
    const { reportVersion } = { ...this.state };
    const url = `/institution/data_systems/search?report_version_id=${reportVersion.id}`;
    if (this.state.tabSubjectDetailsDescriptionEditMode && this.allowBrowse()) {
      return (
        <a
          className="data-model-schema data-model-schema-report-details pretty-button med"
          href={url}
        >
          Browse Data System
        </a>
      );
    }
    return null;
  }

  getHelpPoint() {
    const { props } = this;
    if (this.state.tabSubjectDetailsDescriptionEditMode) {
      return (
        <HelpPoint helpPoint={props.tabSubjectHelpPointEdit || props.tabSubjectHelpPoint} />
      );
    }
    return (
      <HelpPoint helpPoint={props.tabSubjectHelpPoint} />
    );
  }

  errorNoticeContent() {
    if (this.state.reportUpdateErrorMessage)
      return <div className="error">{this.state.reportUpdateErrorMessage}</div>;

    return null;
  }

  render() {
    const { props } = this;
    const { tabSubjectDetailsDescriptionEditMode } = this.state;

    return (
      <div className="tab-subject-details-description preserve-line-breaks">
        <br />
        <div>
          <label
            style={{
              display: 'inline-block'
            }}
          >
            {props.detailsLabel}
          </label>
          {props.editable ? (<img
            src={props.imageEdit}
            style={{
              verticalAlign: 'middle',
              marginLeft: '5px'
            }}
            alt="Edit mapping description icon"
            role="button"
            aria-expanded={tabSubjectDetailsDescriptionEditMode}
            aria-controls="tab-subject-details-desc-editable-area"
            className="submit-section-details-btn"
            onClick={this.state.toggleTabSubjectDetailsDescriptionEditMode}
          />) : ''}
          {this.getHelpPoint()}
          {this.browseDataSystemButton()}
        </div>
        {this.errorNoticeContent()}
        {this.tabSubjectDetailsDescriptionContent()}
      </div>
    );
  }
}
