// Modals before form submit: Update Changed Wiki Links
const WikiLinksUpdateModal = {
  // Start sequence of pre-submit modal whe submit any form
  // If you pass callback to thiss method it shoul contain form submit logic at the end
  startSubmitModalsSequence: (e, callback = null) => {
    const currentForm = $j('.form-with-wiki-links-select-modal');

    // Third modal can be different for different forms (form should be submitted after this modal)
    const finalModal = () => {
      const reportTechnicalTabForm = $j('.edit_report_version_tech_summary');
      const projectTechnicalTabForm = $j('.edit_project_version_technical_summary, .details-section-form-project');
      if (reportTechnicalTabForm.length > 0) {
        ChangeWorkflowDefinitionVersionModal.updateReportTechSummary(e);
      } else if (projectTechnicalTabForm.length > 0) {
        currentForm.submit();
      } else {
        callback ? callback() : WikiLinksUpdateModal.selectFinalModal(e);
      }
    }

    // First modal Update Wiki Links with Any Target Objects except Specification
    const secondModal = () => {
      checkLinksInFields();
      WikiLinksUpdateModal.showUpdateWikiLinkTargetAllObjectsModal(e, finalModal);
    };

    if (currentForm.length > 0) {
        let fields_content = [];
        let changedLinks = [];
        const existingReportsLinks = JSON.parse(sessionStorage.getItem('wikiLinkReportsAttributes'));
        Promise.all(
          Object.values(CKEDITOR.instances).each( async (instance) => {
            let currentData = instance.getData();

            if (existingReportsLinks && existingReportsLinks.length > 0) {
              existingReportsLinks.each((link) => {
                const linkName = link.oldTool ? `${link.oldName}:tool:${link.oldTool}` : link.oldName
                if (currentData.includes(`[[spec:${linkName}]]`)) {
                  const toolName = link.newTool ? ` (${link.newTool})` : '';
                  const toolChanged = link.oldTool === link.newTool ? '' : link.newTool;
                  const nameChanged = link.oldName !== link.newName;
                  const toolNameChanged = link.linkedToolName;
                  changedLinks.push({
                    link: linkName,
                    oldName: link.oldName,
                    newName: link.newName,
                    oldTool: link.oldTool,
                    newTool: toolNameChanged || link.newTool,
                    type: link.type,
                    report_options: [`${link.newName}${toolName}`],
                    changed: true,
                    toolChanged: toolChanged,
                    nameChanged: nameChanged,
                    toolNameChanged: toolNameChanged,
                  })
                };
              })
            }
            fields_content.push(currentData);
          })
        ).then(() => {
          $j.ajax({
            url: '/institution/wiki_links/find_linked_reports',
            method: 'GET',
            data: { fields_content },
            success: (response) => {
              let dataArray = response.data;
              dataArray.push(...changedLinks)
              dataArray = [...new Map(dataArray.map(item => [item.link, item])).values()];

              // First modal Update Wiki Links with Specifications Target
              WikiLinksUpdateModal.showUpdateWikiLinkTargetReportModal(dataArray, e, 0, secondModal);
            }
          }).error((error) => {
            console.error(error);
            secondModal()
          });
        })
    } else {
      secondModal()
    }
  },

  // Show modal to Update Wiki Links with Any Target Objects except Specification
  showUpdateWikiLinkTargetAllObjectsModal: (e, callback) => {
    let currentData = []
    wikiLinks = activeWikiLinks();
    Object.values(CKEDITOR.instances).each((instance) => currentData.push(instance.getData()) )
    wikiLinks = wikiLinks.filter((link) => currentData.join('').includes(`${link.oldName}]]`))

    if (wikiLinks && wikiLinks.length > 0) {
      wikiLinks = [...new Map(wikiLinks.map(item => [item.oldName, item])).values()];
      const modalBody = `${`The name of the following ${wikiLinks.length > 1 ? 'objects' : 'object'} has changed. Do you want the wiki ${wikiLinks.length > 1 ? 'links' : 'link'} updated to the new ${wikiLinks.length > 1 ? 'names' : 'name'}?<hr>` +
        '<ul class="bullets">'}${
        wikiLinks.map((wiki_link) => {
          let message = '';
          if (wiki_link.oldName !== wiki_link.newName)
            message += `<li>${wiki_link.oldName} has changed to ${wiki_link.newName}</li>`;
  
          if (wiki_link.oldTool !== wiki_link.newTool)
            message += `<li>${wiki_link.oldTool || ''} has changed to ${wiki_link.newTool || ''}</li>`;
  
          return message;
        }).join('')
        }</ul><p>If you choose to update the names of these objects, the name is updated in all fields with a wiki link.</p>`;

      $j(`<div>${modalBody}</div>`).dialog({
        resizable: false,
        width: 500,
        height: 'auto',
        modal: true,
        open: function() {
          openUIDialog.call(this);
        },
        buttons: [
          {
            text: 'Yes',
            click: function() {
              $j(this).dialog('close');
              updateFormWithWikiLinkAttributes(e, callback);
            }
          },
          {
            text: 'No',
            click: function() {
              $j(this).dialog('close');
              closeModal(e, callback);
            }
          }
        ]
      });
    } else {
      closeModal(e, callback);
    }
  },

  // Show modal to Update Wiki Links with Specifications Target
  showUpdateWikiLinkTargetReportModal: (dataArray, submitEvent, index = 0, callback) => {
    if (dataArray.length > 0 && index <= (dataArray.length - 1)) {
      const data = dataArray[index];
      const manyOptions = data.report_options.length > 1;
      const headerComponent = getHeaderComponent(data, manyOptions);
      const infoComponent = getInfoComponent(data.link, manyOptions);
      const defaultHeader = headerComponent[0].innerText;
      const defaultInfo = infoComponent[0].innerText;
      let newLink = '';
      let currentLink = '';
      const radioChecked = manyOptions ? '' : 'checked';

      currentLink = data.link;
      newTool = data.changed && data.toolChanged || undefined;
      headerComponent.removeClass('hide')[0].innerText = defaultHeader.replaceAll('<LinkedSpecName>', currentLink.split(':tool:')[0]).replace('<LinkedPrimaryTool>', newTool || (currentLink.split(':tool:')[1] || ''));
      infoComponent.removeClass('hide')[0].innerText = defaultInfo.replace('<LinkedSpecName>', currentLink.split(':tool:')[0]).replace('<LinkedPrimaryTool>', currentLink.split(':tool:')[1] || '');

      data.report_options.map((option, idx) => {
        if (!manyOptions) {
          $j('.create-link-btn').removeClass('disabled-btn');
          newLink = option.replace(' (', ':tool:');
          if (newLink.includes(':tool:')) {
            newLink = newLink.slice(0, -1);
          }
        }
        $j('.report-options-list').append(`<li class="no-bullets"><input type="radio" value="${option}" ${radioChecked} name="reportSelect" id="report-option${idx}" class="report-option-radio-button"/><label class="wiki-link-radio-label" for="report-option${idx}">${option}</label></li>`);
      });

      $j('.report-option-radio-button').on('click', (event) => {
        const radioBtnValue = $j(event.target).val();
        $j('.create-link-btn').removeClass('disabled-btn');
        newLink = radioBtnValue.replace(' (', ':tool:');

        if (newLink.includes(':tool:')) {
          newLink = newLink.slice(0, -1);
        }
      });

      $j('.cancel-wiki-select-btn').on('click', (_event) => {
        window.no_leave_prompt = false;
        resetLayoutToDefault(headerComponent, infoComponent, currentLink);
      });

      $j('.create-missing-spec-btn').on('click', (_event) => {
        if (data.changed) {
          const form = $j('.edit_term_version, .edit_report_version, .edit_quality_issue, .edit_project_version_technical_summary, .edit_report_version_tech_summary, .new_report_filter, .description-edit-field, .details-section-form, .details-section-form-project');
          const formId = form.attr('id').split('_').slice(1, 3).join('_');
          form.append(`<input class="missed-spec-link" type="hidden" name="${formId}[wiki_link_attributes][][missing_link]" value='${currentLink}'>`)
        }
        window.no_leave_prompt = true;
        resetLayoutToDefault(headerComponent, infoComponent, currentLink);
        WikiLinksUpdateModal.showUpdateWikiLinkTargetReportModal(dataArray, submitEvent, index + 1, callback);
      });

      $j('.create-link-btn').on('click', (_event) => {
        if (newLink) {
          Promise.all(
            Object.values(CKEDITOR.instances).each( async (instance) => {
              currentData = instance.getData();
              const newData = currentData.replaceAll(`[[spec:${currentLink}]]`, `[[spec:${newLink}]]`);
              if (newData !== currentData)
                await instance.setData(newData);
            })
          ).then(() => {
            window.no_leave_prompt = true;
            resetLayoutToDefault(headerComponent, infoComponent, currentLink);
            WikiLinksUpdateModal.showUpdateWikiLinkTargetReportModal(dataArray, submitEvent, index + 1, callback);
          })
        }
      });

      $j('.wiki-link-report-modal').dialog({
        modal: true,
        dialogClass: 'dialog-shadow',
        width: 700,
        title: 'Warning',
        close: function () {
          resetLayoutToDefault(headerComponent, infoComponent, currentLink);
        }
      });
    } else {
      callback()
    }
  },

  // Add Wiki Links where Target Object name changed to Session Storage,
  // to use them in Update Wiki Links with Any Target Objects except Specification Modal
  addWikiLinksToStorage: () => {
    if ($j('.old_link_name').length > 0) {
      sessionStorage.setItem('wikiLinkAttributes', JSON.stringify([]));
      sessionStorage.setItem('wikiLinkReportsAttributes', JSON.stringify([]));
      $j('.old_link_name').each((_index, element) => {
        if (element.getAttribute('data_type') === 'report'){
          updateWikiLinks({
            oldName: element.getAttribute('data_old_name'),
            newName: element.getAttribute('data_new_name'),
            oldTool: element.getAttribute('data_old_tool_name'),
            newTool: element.getAttribute('data_new_tool_name'),
            linkedToolName: element.getAttribute('data_linked_tool_new_name'),
            type: element.getAttribute('data_type')
          }, 'wikiLinkReportsAttributes');
        } else {
          updateWikiLinks({
            oldName: element.getAttribute('data_old_name'),
            newName: element.getAttribute('data_new_name'),
            oldTool: '',
            newTool: '',
            type: element.getAttribute('data_type')
          }, 'wikiLinkAttributes');
        }
      });
    }
  },

  selectFinalModal: (e) => {
    const isReport = $j('.form-with-spec-type-modal').length > 0;

    if (isReport) {
      SpecificationTypeModal.showChangeSpecTypeModal(e);
    } else {
      ChangeWorkflowDefinitionVersionModal.showEditWorkflowObjectModal();
    }
  }
};

$j(document).ready(() => {
  $j('#links-table').DataTable({
    columnDefs: [
      {orderable: false, targets: 5},
    ],
    'paging': false,
    'info': false
  });

  WikiLinksUpdateModal.addWikiLinksToStorage();
  handleWikiLinksModal();

  $j('.submit-section-details-btn').click((e) => {
    setTimeout(() => {
      handleWikiLinksModal();
    }, 1000);
  });
});

$j(document).on('show_report_filter', WikiLinksUpdateModal.addWikiLinksToStorage);
$j(document).on('edit_report_filter', handleWikiLinksModal);

function handleWikiLinksModal() {
  const form = $j('.edit_term_version, .edit_report_version, .edit_quality_issue, .edit_project_version_technical_summary, .edit_report_version_tech_summary, .new_report_filter, .description-edit-field, .details-section-form, .details-section-form-project');
  let wikiLinks = JSON.parse(sessionStorage.getItem('wikiLinkAttributes'));
  wikiLinks =  wikiLinks + JSON.parse(sessionStorage.getItem('wikiLinkReportsAttributes'));
  if (($j('.old_link_name').length == 0) && (form && form.length == 0) && (wikiLinks && wikiLinks.length > 0))
    deleteWikiLinks();
  if (form.length > 0) {
    const btn = $j('.edit_term_version_btn, .edit_report_version_btn, edit_quality_issue_btn, .edit_report_filter_description_btn, .edit_tech_summary_btn, .edit_project_version_btn');
    btn.off().click((e) => {
      e.preventDefault();
      WikiLinksUpdateModal.startSubmitModalsSequence(e)
    });
  } else {
    $j('.edit_report_version').on('click', "input.edit_report_version_btn[type='submit'][name='commit']", function (e) {
      e.preventDefault();
      WikiLinksUpdateModal.startSubmitModalsSequence(e)
    });
  }
}

function checkLinksInFields() {
  const wikiLinks = JSON.parse(sessionStorage.getItem('wikiLinkAttributes')) || [];
  let fullText = '';
  Object.keys(CKEDITOR.instances).forEach((key) => {
    fullText = fullText.concat(' ', CKEDITOR.instances[key].getData());
  });
  const fields = $j('#report_version_output_description, #report_version_display_header, #report_version_display_layout, #report_version_display_footer, #change_report_filter_description');

  if (fields.length > 0) {
    fields.each((_index, field) => {
      fullText = fullText.concat(' ', field.value);
    });
  }

  const wikiLinkTypePrefixes = {
    term: ['', 'def:'],
    report: ['spec:'],
    project: ['coll:']
  };
  const toolPostfix = ':tool:';

  wikiLinks.each((wiki_link) => {
    const matchResult = [];
    wikiLinkTypePrefixes[wiki_link.type].each((prefix) => {
      const toolPrefix = wiki_link.oldTool ? `${toolPostfix}${wiki_link.oldTool}` : '';
      regex = new RegExp(`[[${prefix}\s?${wiki_link.oldName}${toolPrefix}]]`);
      matchResult.push(regex.test(fullText));
    });
    if (!matchResult.includes(true)) {
      removeWikiLink(wiki_link);
    } else {
      restoreWikiLink(wiki_link);
    }
  });
}

function updateWikiLinks(value, storageKey) {
  const values = JSON.parse(sessionStorage.getItem(storageKey));
  values.push(value);
  sessionStorage.setItem(storageKey, JSON.stringify(values));
}

function removeWikiLink(value) {
  if (!value.hasOwnProperty('delete')) {
    const values = JSON.parse(sessionStorage.getItem('wikiLinkAttributes'));
    const index = values.findIndex(wiki_link => wiki_link.oldName == value.oldName && wiki_link.newName == value.newName && wiki_link.newTool == value.newTool && wiki_link.oldTool == value.oldTool && wiki_link.type == value.type);
    value.delete = true;
    values[index] = value;
    sessionStorage.setItem('wikiLinkAttributes', JSON.stringify(values));
  }
}

function restoreWikiLink(value) {
  if (value.hasOwnProperty('delete')) {
    const values = JSON.parse(sessionStorage.getItem('wikiLinkAttributes'));
    const index = values.findIndex(wiki_link => wiki_link.oldName == value.oldName && wiki_link.newName == value.newName && wiki_link.newTool == value.newTool && wiki_link.oldTool == value.oldTool && wiki_link.type == value.type);
    delete value.delete;
    values[index] = value;
    sessionStorage.setItem('wikiLinkAttributes', JSON.stringify(values));
  }
}

function activeWikiLinks() {
  let wikiLinks = JSON.parse(sessionStorage.getItem('wikiLinkAttributes')) || [];

  wikiLinks = wikiLinks.filter((wiki_link) => {
    if (wiki_link.hasOwnProperty('delete'))
      return false;

    return true;
  });
  return wikiLinks;
}

function updateFormWithWikiLinkAttributes(_e, callback) {
  addWikiLinkAttributes();
  deleteWikiLinks();
  callback();
}

function addWikiLinkAttributes() {
  const form = $j('.edit_term_version, .edit_report_version, .edit_quality_issue, .edit_project_version_technical_summary, .edit_report_version_tech_summary, .new_report_filter, .description-edit-field, .details-section-form, .details-section-form-project');
  const formId = form.attr('id').split('_').slice(1, 3).join('_');
  const wikiLinks = activeWikiLinks();
  form.append(wikiLinks.map(wiki_link => `<input class="old-name-field" type="hidden" name="${formId}[wiki_link_attributes][][old_name]" value='${wiki_link.oldName}'>` +
      `<input class="new-name-field" type="hidden" name="${formId}[wiki_link_attributes][][new_name]" value='${wiki_link.newName}'>` +
      `<input class="old-tool-field" type="hidden" name="${formId}[wiki_link_attributes][][old_tool]" value='${wiki_link.oldTool || ''}'>` +
      `<input class="new-tool-field" type="hidden" name="${formId}[wiki_link_attributes][][new_tool]" value='${wiki_link.newTool || ''}'>` +
      `<input class="type-field" type="hidden" name="${formId}[wiki_link_attributes][][type]" value='${wiki_link.type}'>`).join(''));
}

function closeModal(e, callback) {
  deleteWikiLinks();
  callback();
}

function deleteWikiLinks() {
  sessionStorage.removeItem('wikiLinkAttributes');
}

const resetLayoutToDefault = (headerComponent, infoComponent, currentLink) => {
  const defaultHeader = headerComponent[0].innerText;
  const defaultInfo = infoComponent[0].innerText;

  $j('.wiki-link-report-modal').dialog('destroy');
  $j('.report-options-list').empty();
  $j('.create-link-btn').addClass('disabled-btn');
  headerComponent.addClass('hide')[0].innerText = defaultHeader.replaceAll(currentLink.split(':tool:')[0], '<LinkedSpecName>').replace(currentLink.split(':tool:')[1], '<LinkedPrimaryTool>');
  infoComponent.addClass('hide')[0].innerText = defaultInfo.replace(currentLink.split(':tool:')[0], '<LinkedSpecName>').replace(currentLink.split(':tool:')[1], '<LinkedPrimaryTool>');
};

const getHeaderComponent = (data, manyOpts = false) => {
  const withTool = data.link.includes(':tool:')

  if (data.changed) {
    const toolChanged = data.toolChanged
    const nameChanged = data.nameChanged
    const toolNameChanged = data.toolNameChanged

    if (toolNameChanged) {
      return $j('.header-text-tool-name-changed');
    } else if (toolChanged && nameChanged) {
      return $j('.header-text-name-and-tool-changed');
    } else if (nameChanged) {
      return $j('.header-text-name-changed');
    } else {
      return $j('.header-text-tool-changed');
    }
  } else if (withTool) {
    return $j(manyOpts ? '.header-text-many-opts' : '.header-text-one-opt');
  } else {
    return $j('.header-text-not-unique');
  }
};

const getInfoComponent = (link, manyOpts = false) => {
  const withTool = link.includes(':tool:');

  if (withTool)
    return $j(manyOpts ? '.info-text-many-opts' : '.info-text-one-opt');

  return $j(manyOpts ? '.info-text-not-unique-many-opts' : '.info-text-not-unique');
};

if (module) {
  module.exports.WikiLinksUpdateModal = WikiLinksUpdateModal;
}

